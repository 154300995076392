/*
 * jQuery SmoothScroll v1.0
 * Copyright 2017 mukutu GmbH
 * Contributing Authors: Mark Leewe
 */
import $ from 'jquery';
window.jQuery = window.$ = $;

(function ($) {
    $.fn.smoothScroll = function () {
        $('a.jumplabelLink').on('click', function (event) {
            if (this.hash !== '') {
                event.preventDefault();
                let hash = this.hash;
                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 800, function () {
                    window.location.hash = hash;
                });
            }
        });
    };
}(jQuery));
