<template>
    <div class="door-search-widget">
        <span><input id="doorSearchInput" type="text" v-model="searchText" :placeholder="translations.placeholderEnterSearchTerm" @focus="fetchDoors()"/></span>
        <p v-if="error">{{ error }}</p>
        <template v-if="error === null">
            <div v-if="doors === null && loading"><span>{{ translations.resultsAreLoading }}</span><i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></div>
            <ul v-if="searchText && doors !== null && Object.keys(filteredDoors).length">
                <template v-for="entry in filteredDoors" :key="entry">
                    <li class="door-search-series-title">
                        {{ entry.seriesTitle }}
                    </li>
                    <li v-for="door in entry.doors" :key="door.id">
                        <a :href="'/' + locale + door.url" @click="scrollOrNavigate(door.hash, $event)">
                            <div class="door-search-result">
                                <div>
                                    <span>{{ door.serialNo }}</span>
                                </div>
                                <img :src="door.imageOutside">
                            </div>
                        </a>
                    </li>
                </template>
            </ul>
            <p v-if="doors !== null && filteredDoors.length === 0">Wij kunnen geen voordeur met uw modelnummer vinden. U vindt uw model in <a class="white-link" href="https://polytec.traumtuer-konfigurator.de/?conf=fb80e440&lang=nl" target="_blank">onze deurenconfigurator</a>!</p>
        </template>
    </div>
</template>

<script>

const defaultSeriesSorting = [
    'doors/modern',
    'doors/art-decor-line',
    'doors/exclusive/concrete',
    'doors/exclusive/corten',
    'doors/exclusive/steel',
    'doors/exclusive/stone',
    'doors/exclusive/multi',
    'doors/exclusive/led',
    'doors/exclusive/crystal',
    'doors/exclusive/colani',
    'doors/exclusive/holder',
    'doors/exclusive/lignum',
    'doors/exclusive/feng-shui',
    'doors/nostalgie',
    'doors/classic',
];

export default {
    data: () => ({
        searchText: '',
        doors: null,
        error: null,
        loading: false,
        translations: window.frontendTranslations.doorSearch,
        locale: window.locale
    }),
    props: ['series'],
    computed: {
        filteredDoors() {
            let vm = this;
            let doors = vm.doors;
            if (vm.searchText) {
                doors = doors.filter(door => {
                    return door.serialNo.indexOf(vm.searchText) > -1
                });
            }
            // group by category
            let doorsByCategory = {};
            doors.map(door => {
                if (!doorsByCategory.hasOwnProperty(door.series)) {
                    doorsByCategory[door.series] = [];
                }
                doorsByCategory[door.series].push(door);
            });
            // sorting
            let seriesSorting = defaultSeriesSorting;
            if (vm.series && vm.series.length) {
                seriesSorting = defaultSeriesSorting.filter(series => series !== vm.series);
                seriesSorting.unshift(vm.series);
            };

            let result = seriesSorting.map(series => {
                if (!doorsByCategory[series]) {
                    return null;
                }
                return {
                    seriesTitle: doorsByCategory[series][0].seriesTitle,
                    doors: doorsByCategory[series],
                };
            }).filter(object => object !== null);
            return result;
        },
    },
    methods: {
        fetchDoors() {
            if (this.doors === null) {
                this.loading = true;
                return fetch('/api/doors/search/get').then(response => {
                    if (!response.ok) throw 'Doors api responded with '+response.status;
                    return response.json();
                }).then(json => {
                    this.doors = json;
                }).catch(e => {
                    this.error = this.translations.pleaseTryLater;
                    console.log(e);
                });
            }
        },
        scrollOrNavigate(hash, event) {
            let vm = this;
            let $targets = $(hash);
            if ($targets.length) {
                event.preventDefault();
                let $target = $targets.first();
                vm.resetHighlight();
                $target.addClass('highlight');
                vm.searchText = '';
                let scrollOptions = {
                    top: $target.offset().top - 100,
                    left: 0,
                    behavior: 'smooth',
                };
                // // native smooth scrolling
                // window.scrollTo(scrollOptions);
                $('html, body').animate({
                    scrollTop: scrollOptions.top
                }, 2000);
            }
        },
        resetHighlight() {
            $('[id^=\'door-\']').removeClass('highlight');
        },
    },
};
</script>

<style lang="scss">

</style>
