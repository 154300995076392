/*
 * jQuery VisibleAddClass v1.2
 * Copyright 2016 mukutu GmbH
 * Contributing Authors: Mark Leewe
 * Requires: visible
 */

import $ from 'jquery';
window.jQuery = window.$ = $;

(function ($) {
    $.fn.visibleAddClass = function (optionsCustom) {
        let sourceElement = $(this);
        let optionsDefault = {
            class: 'in',
            removeWhenInvisible: false
        };
        let options = $.extend(optionsDefault, optionsCustom);

        let addClass = function () {
            sourceElement.each(function () {
                if ($(this).visible(true)) {
                    $(this).addClass(options.class);
                }
                else if (options.removeWhenInvisible === true) {
                    $(this).removeClass(options.class);
                }
            });
        };

        $(window).scroll(function (event) {
            addClass();
        });

        $(document).ready(function () {
            addClass();
        });
    };

}(jQuery));
