/*
 * jQuery EmailEncrypt v1.0
 * Copyright 2015 mukutu GmbH
 * Contributing Authors: Mark Leewe
 */
import $ from 'jquery';
window.jQuery = window.$ = $;

(function ($) {
    $.fn.emailEncrypt = function () {
        var hash = $(this).text();
        var content = unescape(hash).replace(/#/g, "@").replace(/,/g, ".");
        $(this).replaceWith(content);
    };
}(jQuery));