/*
 * jQuery Coolify v1.0
 * Copyright 2017 mukutu GmbH
 * Contributing Authors: Mark Leewe, Markus Daul, Jakob Fischer
 */
import $ from 'jquery';
window.jQuery = window.$ = $;

(function ($) {
    $.fn.coolify = function (optionsCustom) {
        let pageChangeDone = false;
        let sourceElement = $(this);
        let optionsDefault = {
            navigation: 'navigationWrapper',
            additionalUpdates: ['#navigation'],
            showClassName: '_show',
            activeClassName: '_active',
            bindTo: 'a[href]:not([href^="mailto\\:"], [href^="tel\\:"], [href^="http"], [href*="#"], [href^="\/img\/"], [href^="\/pdf\/"])',
            onload: function(pageUrl) {}
        };

        let options = $.extend(optionsDefault, optionsCustom);

        let replaceContent = function (data, pageUrl) {
            data.replace('<!DOCTYPE html>', '');

            // replace meta
            let title = $(data).filter('title').text();
            let description = $(data).filter('meta[name=description]').attr('content');

            $(document).attr('title', title);
            $('meta[name=description]').attr('content', description);

            // replace contents
            //let content = $(data).filter('#' + sourceElement.attr('id')).html();
            let content = $(data).find('#' + sourceElement.attr('id')).html();

            for (let i = 0; i < options.additionalUpdates.length; i++) {
                let selector = options.additionalUpdates[i];
                let newContent = $(data).find(selector).html();
                let $eltToUpdate = $(selector);
                $eltToUpdate.html(newContent).find(options['bindTo']).click(clickAction);
                $eltToUpdate.trigger('contentUpdate');
            }

            // set right navigation point to active
            $(options.navigation).removeClass('_active');
            $(options.navigation).find('.dropdown').removeClass('open');
            $(options.navigation).find('.active').removeClass('active');
            $(options.navigation).removeClass('in');

            sourceElement.html(content);
            sourceElement.fadeTo('slow', 1);

            // rebind behaviour override
            sourceElement.find(options.bindTo).click(clickAction);

            // callback function
            options.onload.call(this, pageUrl);
        };

        let clickAction = function (event) {
            let pageUrl = $(this).attr('href');

            let hostWithProtocol = window.location.protocol + '//' + window.location.host;
            if (window.history.pushState) {
                event.preventDefault();

                if (hostWithProtocol + pageUrl !== window.location) {
                    // scroll to top
                    $("html, body").animate({scrollTop: 0}, 600);

                    sourceElement.fadeTo('slow', 0.0001, function () {
                        $.ajax({
                            url: pageUrl,
                            dataType: 'html',
                            success: function (data) {
                                replaceContent(data, pageUrl);

                                // push state
                                window.history.pushState({}, '', pageUrl);
                                pageChangeDone = true;
                            },
                            error: function () {
                                window.location.href = pageUrl;
                            }
                        });
                    });

                    return false;
                }
            }
        };

        // behaviour override
        $(options['bindTo']).click(clickAction);

        // override back button to get the ajax content without page reload
        $(window).bind('popstate', function () {
            if (window.history.pushState && pageChangeDone) {
                $.ajax({
                    url: location.pathname,
                    dataType: 'html',
                    success: function (data) {
                        replaceContent(data, location.pathname);
                    }
                });
            }
        });
    };

}(jQuery));