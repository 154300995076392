/*
 * jQuery FunctionRunner v1.0
 * Copyright 2015 mukutu GmbH
 * Contributing Authors: Mark Leewe
 */
import $ from 'jquery';
window.jQuery = window.$ = $;

(function ($) {
    $.fn.functionRunner = function () {
        var sourceElement = $(this);

        sourceElement.each(function () {
            var $this, dataProps;
            $this = $(this);
            dataProps = $this.data();

            if (typeof $this[dataProps.function] === "function") {
                return $this[dataProps.function](dataProps);
            }
        });
    };
}(jQuery));