/*
 * jQuery activeToggle v1.4.1
 * Copyright 2017 mukutu GmbH
 * Contributing Authors: Mark Leewe
 */

import $ from 'jquery';
window.jQuery = window.$ = $;

$(document).ready(function () {
    $(window).resize(function () {

        if($(document).width() <= 992) {

            var headlineheight = $('.bg-red').outerHeight();

            console.log(headlineheight);

            $('.introImageMobile, .introImage-smallMobile').css('margin-top', headlineheight);

            $('.introImage-smallMobile.fullHeight, .introImage-smallMobile.navigationMargin').css('margin-top', headlineheight + 79);

            $('.introImageMobile .bg-red, .introImage-smallMobile .bg-red').css('margin-top', -headlineheight);

        } else {

            $('.introImageMobile, .introImage-smallMobile').css('margin-top', 0);

            $('.introImageMobile .bg-red, .introImage-smallMobile .bg-red').css('margin-top', 0);

        }


    }).trigger('resize');

});