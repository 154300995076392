import '../scss/style.scss';
import {polyfill} from "es6-promise";
import 'whatwg-fetch';
import './mukutu/jquery.coolify';

import './mukutu/jquery.email-encrypt.js';
import './mukutu/jquery.function-runner.js';
import './mukutu/jquery.form-focus.js';
import './mukutu/jquery.smooth-scroll';
import './mukutu/jquery.active-toggle.js';
//import './vendor/password-hide-show.js';
import './mukutu/jquery.visible';
import './mukutu/jquery.visible-add-class';
import './mukutu/jquery.header-mobile';
import 'flexslider';
import 'slick-carousel';
import 'beefup/src/js/jquery.beefup.js';

import initDoorSearch from './door-search/initDoorSearch';
import {addClass} from "modal-video/lib/lib/util";
initDoorSearch('.door-search-widget');

let removeHash = () => {
    // works in Chrome 9, Firefox 4, Safari 5, Opera 11.50 and in IE 10
    // https://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-url-with-javascript-without-page-r/5298684#5298684
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history)
        history.pushState("", document.title, loc.pathname + loc.search);
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

// scroll to door hash
if (window.location.hash && window.location.hash.startsWith('#door-')) {
    let hash = window.location.hash;
    removeHash();
    setTimeout(function() {
        window.scrollTo(0, 0)
    }, 1);
    $(window).on('load', () => {
        let $targets = $(hash);
        if ($targets.length) {
            let $target = $targets.first();
            $target.addClass('highlight')
            let scrollOptions = {
                top: $target.offset().top - 100,
                left: 0,
                behavior: 'smooth',
            };
            // // native smooth scrolling
            // window.scrollTo(scrollOptions);
            $('html, body').animate({
                scrollTop: scrollOptions.top
            }, 2000);
        }
    });
}

$(document).ready(function () {
    $('#doorSearchInput').click(function () {
        $('html, body').animate({scrollTop: $(this).offset().top -100 }, 'slow');
    });

    $(document).on('click', function () {
        $(".door-search-widget ul").hide();
    });
});

window.ytPlayer = null;
window.onYouTubeIframeAPIReady = function() {
    window.ytPlayer = new YT.Player('player', {
        videoId: 'kZ3cI_kk70U',
        playerVars: {
            rel: 0,
            controls: 0,
            showinfo: 0,
            autoplay: 0
        },
    });
};

/*
*  Cookie Banner by epunks GmbH
*  trainee for software engineering Martin Glück
 */

/** Set Cookie
 * @param cname
 * @param cvalue
 * @param exdays
 */
function setCookie( cname, cvalue, exdays )
{
    var d           = new Date();
    d.setDate(d.getDate() + (exdays));
    var expires     = "expires=" + d.toUTCString();
    document.cookie = cname +"=" + cvalue + ";"+ expires + ";path=/" ;

}

/** Remove Cookie
 *
 * @param cname
 */
function removeCookie (cname) {
    document.cookie = cname + "=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT"
}

/** Get actual Cookie
 *
 * @param cname
 * @returns {*}
 */
function returnCookie ( cname )
{
    var name       = cname + "=";
    var ca = document.cookie.split(';');
    for(var i      = 0; i < ca.length; i++)
    {
        var c      = ca[i];
        while (c.charAt(0) == ' ')
        {
            c      = c.substring(1);
        }
        if (c.indexOf(name) == 0)
        {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/** Hide div on click
 *
 */
function rmdiv()
{
    let cookieBanner = document.getElementById("cookie-banner");

    if(cookieBanner) {
        cookieBanner.setAttribute('style', 'display:none');
    }
}

/** Check if action cookie exists, otherwise displaydiv() and initEventlistener() are triggered
 *
 */
function checkCookie()
{
    var cookie_accept      = returnCookie("cookie-accept");
    var cookie_decline     = returnCookie("cookie-decline");
    if (cookie_accept || cookie_decline)
    {
        rmdiv();
        var a       = document.createTextNode("");
        document.body.appendChild(a);
        // manipulate tags
        manipulateScriptTag();
    } else {
        let cookieBanner = document.getElementById("cookie-banner");

        if (cookieBanner) {
            cookieBanner.setAttribute('style', 'display:block');
            initEventListener();
            // manipulate tags
            manipulateScriptTag();
        }
    }
}

/**
 * Switch to Decline Action in privacy policy
 */
function switchToDecline(){
    setCookieDecline();
    declineManipulateStripTags();
}

/**
 * Switch to Accept Action in privacy policy
 */
function switchToAccept(){
    setCookieAccept();
    manipulateScriptTag();
}

/**
 * Eventlistener, onclick function: set Cookie, hide the Consent, reload page with setted cookie
 */
function initEventListener ()
{
    document.getElementById('btn-accept').addEventListener("click", function()
    {
        setCookieAccept();
        rmdiv();
        manipulateScriptTag();
        window.location.reload(true);
    });
    document.getElementById('btn-reject').addEventListener("click", function()
    {
        setCookieDecline();
        rmdiv();
        declineManipulateStripTags();
        window.location.reload(true);
    });

}

function initRevertOptions(){
    var a_decline           = document.createElement("A");
    var a_accept            = document.createElement("A");
    var p_info              = document.createElement("P");
    var a_decline_text      = document.createTextNode("Afwijzen");
    var a_accept_text       = document.createTextNode("Accepteren");

    a_decline.className     = "revert-a-tag";
    p_info.className        = "info_p";
    a_accept.className      = "revert-a-tag";
    a_decline.id            = "decline-revert-option";
    a_accept.id             = "accept-revert-option";
    a_decline.href          = "#";
    a_accept.href           = "#";


    if (returnCookie("cookie-accept")){
        var p_info_text         = document.createTextNode("U heeft momenteel cookies geactiveerd");
    } else{
        var p_info_text         = document.createTextNode("U heeft momenteel cookies gedeactiveerd");
    }

    p_info.appendChild(p_info_text);
    a_accept.appendChild(a_accept_text);
    a_decline.appendChild(a_decline_text);

    if(document.getElementById("replace-cookie-revert") !== null) {

        document.getElementById("replace-cookie-revert").appendChild(p_info);
        document.getElementById("replace-cookie-revert").appendChild(a_accept);
        document.getElementById("replace-cookie-revert").appendChild(a_decline);

        //Check for toggle Cookies
        if (returnCookie("cookie-decline")) {
            document.getElementById("decline-revert-option").setAttribute('style', 'display:none');
        }
        if (returnCookie("cookie-accept")) {
            document.getElementById("accept-revert-option").setAttribute('style', 'display:none');
        }


        document.getElementById("decline-revert-option").addEventListener("click", function () {
            setCookieDecline();
            declineManipulateStripTags();
            window.location.reload(true);
        });

        document.getElementById("accept-revert-option").addEventListener("click", function () {
            setCookieAccept();
            manipulateScriptTag();
            window.location.reload(true);
        });
    }
}

/**
 * set Cookie for accepting cookies
 */
function setCookieAccept() {
    if (returnCookie("cookie-decline")) {
        removeCookie("cookie-decline");
    }
    setCookie('cookie-accept', true, 32850);
}

/**
 * set Cookie for not accepting cookies
 */
function setCookieDecline() {
    if (returnCookie("cookie-accept")) {
        removeCookie("cookie-accept");
    }
    setCookie('cookie-decline', true, 32850);
}


/**
 * remove Element
 */
function removeElement(elemtId){

    var element = document.getElementById(elemtId);
    element.parentNode.removeChild(element);
}

/**
 *  manipulate script and iframe tags
 */
function manipulateScriptTag() {
    if (returnCookie("cookie-accept")) {
        // check all script-tags and find if they have the cookie-consent attribute
        var scripts = document.getElementsByTagName("script");
        for (var i = 0; i < scripts.length; ++i) {
            if( scripts[i].getAttribute('data-cookieconsent') !== "undefined" && scripts[i].getAttribute('data-cookieconsent') !== null ){
                var ga_script = scripts[i].innerHTML;
                scripts[i].id   = "delete-script";
                var new_script  = document.createElement("SCRIPT");
                var new_script_text = document.createTextNode(ga_script);

                new_script.setAttribute("data-cookieconsent-accepted", "statistics");
                new_script.setAttribute("type", "text/javascript");


                new_script.appendChild(new_script_text);
                document.head.appendChild(new_script);
                removeElement("delete-script");

            }
        }
        // check all iframe-tags and find if they have the cookie-consent attribute
        var iframes = document.getElementsByTagName("iframe");
        for (var i = 0; i < iframes.length; ++i) {
            if( iframes[i].getAttribute('data-cookieconsent') !== "undefined" && iframes[i].getAttribute('data-cookieconsent') !== null ){
                var data_src = iframes[i].getAttribute("data-src");
                iframes[i].setAttribute("data-src", "");
                iframes[i].setAttribute("src", data_src);
            }
        }
    }
}

/**
 *  manipulate script and iframe tags
 */
function declineManipulateStripTags() {
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
        if( scripts[i].getAttribute('data-cookieconsent-accepted') !== "undefined" && scripts[i].getAttribute('data-cookieconsent-accepted') !== null){
            scripts[i].setAttribute("type", "text/plain");
            var cookieconsent_val = scripts[i].getAttribute("data-cookieconsent-accepted");
            scripts[i].setAttribute("data-cookieconsent", cookieconsent_val );
            console.log(scripts);
        }
    }
    // check all iframe-tags and find if they have the cookie-consent attribute
    var iframes = document.getElementsByTagName("iframe");
    for (var i = 0; i < iframes.length; ++i) {
        if( iframes[i].getAttribute('data-cookieconsent') !== "undefined" && iframes[i].getAttribute('data-cookieconsent') !== null){
            var src = iframes[i].getAttribute("src");
            iframes[i].setAttribute("src", "");
            iframes[i].setAttribute("data-src", src);
        }
    }
    setCookieDecline();
}

window.onload = function (){
    checkCookie();initRevertOptions();
};


$(document).ready(function() {

    $(function () {
        $('[data-active-toggle]').activeToggle();
        //$('nav').navigationToggle();
        $('[data-function]').functionRunner();
        //$('.flexslider').flexslider({ animation: "slide" });
        //$('#content').coolify({
        //    navigation: '.navigationWrapper',
        //});
        //$('.map').googleMapsPreventScrolling();
        //$('.hoverElement a').touchHover();
        $().smoothScroll();
    });

    let header = $('header');
    let navigation = $('.navigationWrapper');
    let navigationToggle = $('.navigationToggle');
    let languageNavigation = $('.languageWrapper');
    let languageIcon = $('.languageIcon');
    let w = $(window);

    // add classes by scroll position
    let position = $(window).scrollTop();
    let winHeight = $(window).height();
    let docHeight = $(document).height();
    $(window).scroll(function () {
        let scrollPercent = (position) / (docHeight - winHeight);
        let scrollPercentRounded = Math.round(scrollPercent*100);
        let scroll = $(window).scrollTop();
        try {
            let offset = $('.breadcrumb').offset();
            if(!$('.breadcrumb').hasClass("_sticky")) {
                if(scroll >= 350) {
                    $('.breadcrumb').addClass("_sticky");
                    $('.breadcrumbWrapper').addClass("_sticky");
                    $('.breadcrump_placeholder').addClass("_active");
                }
            }
            else {
                if(scroll < 1) {
                    $('.breadcrumb').removeClass('_sticky');
                    $('.breadcrumbWrapper').removeClass('_sticky');
                    $('.breadcrump_placeholder').removeClass("_active");
                }
            }
        }
        catch(err) {
            //
        }

        if ($(document).scrollTop() > 5) {
            header.addClass('_shrink');
            navigation.addClass('_shrink');
            languageNavigation.removeClass('_active');
            languageIcon.removeClass('_active');

        } else {
            header.removeClass('_shrink');
            navigation.removeClass('_active');
            navigation.removeClass('_shrink');
            navigationToggle.removeClass('_active');
        }

        if (scroll >= 50 && scroll > position) {
            header.addClass('_hide');
            navigation.removeClass('_active');
            navigationToggle.removeClass('_active');
            try {
                $('.breadcrumb').removeClass('_scroll');
                $('.breadcrumbWrapper').removeClass('_scroll');
            }
            catch(err) {
                //
            }
        } else {
            header.removeClass('_hide');
            try {
                $('.breadcrumb').addClass('_scroll');
                $('.breadcrumbWrapper').addClass('_scroll');
            }
            catch(err) {
                //
            }
        }
        position = scroll;
    });

    $("#wrap").addClass("_show");
    $(".headerSlider").slick({
        prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"></button>',
        nextArrow: '<button class="slick-next" aria-label="Next" type="button"></button>',
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        autoplay: true
    });
    $(".slider").slick({
        prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"></button>',
        nextArrow: '<button class="slick-next" aria-label="Next" type="button"></button>',
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: "unslick"
            }
        ]
    });
    $(".contentSlider").slick({
        prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"></button>',
        nextArrow: '<button class="slick-next" aria-label="Next" type="button"></button>',
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    dots: false
                }
            }
        ]
    });

    $(".showcaseSlider").slick({
        prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"></button>',
        nextArrow: '<button class="slick-next" aria-label="Next" type="button"></button>',
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: "unslick"
            }
        ]
    });

    $("#tab1_button").click(function(event){
        if(!$(this).hasClass("_active")) {
            $(this).addClass("_active");
            $("#tab1_text").addClass("_active");
            $("#tab1_list").addClass("_active");

            $("#tab2_button").removeClass("_active");
            $("#tab2_text").removeClass("_active");
            $("#tab2_list").removeClass("_active");
        }
    });

    $("#tab2_button").click(function(event){
        if(!$(this).hasClass("_active")) {
            $(this).addClass("_active");

            $("#tab2_text").addClass("_active");
            $("#tab2_list").addClass("_active");

            $("#tab1_button").removeClass("_active");
            $("#tab1_text").removeClass("_active");
            $("#tab1_list").removeClass("_active");
        }
    });

    // Accordion
    $(function() {
        $('.beefup').beefup({
            openSingle: true,
        });
    });

    $( ".beefup" ).click(function() {
        if ( $( this ).hasClass( "is-open" ) ) {
        } else {
            $('html, body').animate({
                scrollTop: $(this).find(".accordion").offset().top - 65
            }, 1000)
        }
    });

    // Bildupload
    $("a.buttonFileUpload").click(function(){
        $(this).parent().find('input[type="file"]').trigger("click");
    });

    $("a.buttonFileUpload").parent().find('input[type="file"]').on('change', function() {
        $(this).parent().find("a.buttonFileUpload span").text(this.files[0]['name']);
    });

    // // Door animation
    // $(".showcaseGrid .click-it").click(function() {
    //     $(this).parent().parent().find(".outside").toggleClass( "outside-active" );
    //     $(this).parent().parent().find(".inside").toggleClass( "inside-active" );
    // });

    var randomthis = Math.floor((Math.random()*6)+1);

    let testimonialSlider = $('.testimonialSlider');
    testimonialSlider.flexslider({
        animation: "slide",
        directionNav: false,
        controlNav: false,
        slideshow: false,
        smoothHeight: true,
        startAt: randomthis,
    });
    $('.testimonialSlider + .flex-direction-nav-nutzerstimmen a.flex-next').click(function(e) {
        e.preventDefault();
        testimonialSlider.flexslider('next');
    });
    $('.testimonialSlider + .flex-direction-nav-nutzerstimmen a.flex-prev').click(function(e) {
        e.preventDefault();
        testimonialSlider.flexslider('prev');
    });

    // init video modal if present
    var $modal = $('.modal')
    if ($modal.length) {
        $('.modal-button').click(function() {
            $modal.toggle( "fast", function() {
            });
            $("body").addClass("modal-open");
            window.ytPlayer.playVideo();
        });
        $('.modal-close').click(function() {
            $modal.toggle( "fast", function() {
            });
            $("body").removeClass("modal-open");
            window.ytPlayer.pauseVideo();
        });
    }
});