/*
 * jQuery activeToggle v1.4.1
 * Copyright 2017 mukutu GmbH
 * Contributing Authors: Mark Leewe
 */

import $ from 'jquery';
window.jQuery = window.$ = $;

(function ($) {
    $.fn.activeToggle = function () {
        let sourceElement = $(this);
        sourceElement.each(function () {
            let toggleClassName = $(this).data().activeToggleClass || "_active";
            let targetName = $(this).data().activeToggle;
            let groupName = $(this).data().activeGroup;

            // Toggle on click event
            $(this).on('click', function (event) {
                event.preventDefault();

                $('[data-active-toggle="' + targetName + '"], [data-active-scope="' + targetName + '"]').toggleClass(toggleClassName);

                if (typeof groupName != 'undefined') {
                    $('[data-active-group="' + groupName + '"]:not([data-active-toggle="' + targetName + '"], [data-active-scope="' + targetName + '"])').removeClass(toggleClassName);
                } else {
                    $('[data-active-toggle]:not([data-active-toggle="' + targetName + '"]), [data-active-scope]:not([data-active-scope="' + targetName + '"])').removeClass(toggleClassName);
                }
            });
        });
    };
}(jQuery));