import $ from 'jquery';
window.jQuery = window.$ = $;

import classie from 'desandro-classie';
/*
 * jQuery formFocus v1.0
 * Copyright 2017 mukutu GmbH
 */

(function ($) {
    $.fn.formFocus = function () {
        if (!String.prototype.trim) {
            (function () {
                // Make sure we trim BOM and NBSP
                var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
                String.prototype.trim = function () {
                    return this.replace(rtrim, '');
                };
            })();
        }

        [].slice.call(document.querySelectorAll('input')).forEach(function (inputEl) {
            // in case the input is already filled..
            if (inputEl.value.trim() !== '') {
                classie.add(inputEl.parentNode, 'input--filled');
            }

            // events:
            inputEl.addEventListener('focus', onInputFocus);
            inputEl.addEventListener('input', onInputFocus);
            inputEl.addEventListener('blur', onInputBlur);
        });

        function onInputFocus(ev) {
            classie.add(ev.target.parentNode, 'input--filled');
        }

        function onInputBlur(ev) {
            if (ev.target.value.trim() === '') {
                classie.remove(ev.target.parentNode, 'input--filled');
            }
        }

        [].slice.call(document.querySelectorAll('textarea')).forEach(function (textareaEl) {
            // in case the input is already filled..
            if (textareaEl.value.trim() !== '') {
                classie.add(textareaEl.parentNode, 'textarea--filled');
            }

            // events:
            textareaEl.addEventListener('focus', onTextareaFocus);
            textareaEl.addEventListener('blur', onTextareaBlur);
        });

        function onTextareaFocus(ev) {
            classie.add(ev.target.parentNode, 'textarea--filled');
        }

        function onTextareaBlur(ev) {
            if (ev.target.value.trim() === '') {
                classie.remove(ev.target.parentNode, 'textarea--filled');
            }
        }
    };
}(jQuery));
