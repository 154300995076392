import './bootstrap';

import jQuery from 'jquery';
window.$ = jQuery;

import 'es6-promise';
import 'whatwg-fetch';
import './mukutu/jquery.coolify.js';
import './mukutu/jquery.email-encrypt.js';
import './mukutu/jquery.function-runner.js';
import './mukutu/jquery.form-focus.js';
import './mukutu/jquery.smooth-scroll';
import './mukutu/jquery.active-toggle.js';
//import './vendor/password-hide-show.js';
import './mukutu/jquery.visible';
import './mukutu/jquery.visible-add-class';
import './mukutu/jquery.header-mobile';
import 'flexslider/jquery.flexslider';
import '../../node_modules/slick-carousel/slick/slick.js';
import '../../node_modules/beefup/src/js/jquery.beefup';

import './script';