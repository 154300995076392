import $ from 'jquery';
import Vue from 'vue';
import DoorSearch from './DoorSearch.vue';

/**
 *
 * @param selector the jquery selector for the root elements
 */
export default selector => {
    $(document).ready(() => {
        let elements = $(selector);
        if (elements.length) {
            elements.each((index, element) => {
                let series = $(element).data('series');
                new Vue({
                    el: element,
                    render: (h) => {
                        return h(DoorSearch, {props: {series: series}});
                    },
                })
            });
        }
    });
}