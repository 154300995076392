/*
 * jQuery Visible v1.0
 * Copyright 2016 mukutu GmbH
 * Contributing Authors: Mark Leewe
 */
import $ from 'jquery';
window.jQuery = window.$ = $;

(function ($) {
    $.fn.visible = function (partial, hidden, direction) {
        if (this.length < 1) {
            return;
        }

        let $t = this.length > 1 ? this.eq(0) : this,
            w = $(window),
            t = $t.get(0),
            vpWidth = w.width(),
            vpHeight = w.height(),
            direc = (direction) ? direction : 'both',
            clientSize = hidden === true ? t.offsetWidth * t.offsetHeight : true;

        if (typeof t.getBoundingClientRect === 'function') {
            // Use this native browser method, if available.
            let rec = t.getBoundingClientRect(),
                tViz = rec.top >= 0 && rec.top < vpHeight,
                bViz = rec.bottom > 0 && rec.bottom <= vpHeight,
                lViz = rec.left >= 0 && rec.left < vpWidth,
                rViz = rec.right > 0 && rec.right <= vpWidth,
                vVisible = partial ? tViz || bViz : tViz && bViz,
                hVisible = partial ? lViz || rViz : lViz && rViz;

            if (direc === 'both')
                return clientSize && vVisible && hVisible;
            else if (direc === 'vertical')
                return clientSize && vVisible;
            else if (direc === 'horizontal')
                return clientSize && hVisible;
        } else {
            let viewTop = w.scrollTop(),
                viewBottom = viewTop + vpHeight,
                viewLeft = w.scrollLeft(),
                viewRight = viewLeft + vpWidth,
                offset = $t.offset(),
                _top = offset.top,
                _bottom = _top + $t.height(),
                _left = offset.left,
                _right = _left + $t.width(),
                compareTop = partial === true ? _bottom : _top,
                compareBottom = partial === true ? _top : _bottom,
                compareLeft = partial === true ? _right : _left,
                compareRight = partial === true ? _left : _right;

            if (direc === 'both')
                return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop)) && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
            else if (direc === 'vertical')
                return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop));
            else if (direc === 'horizontal')
                return !!clientSize && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
        }
    };
}(jQuery));